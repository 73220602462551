import routes from '../../../routers/routes';
import _common from './_common';

export default {
  productions: {
    title: 'Prehľad produkcie',
    quickLinks: {
      title: 'Rýchle odkazy',
      links: [
        {
          title: 'Všetko',
          icon: 'all',
          href: `${routes.extranet.prihlasky}?state=all`,
        },
        {
          title: 'Akceptované',
          icon: 'accepted',
          href: `${routes.extranet.prihlasky}?state=accepted`,
        },
        {
          title: 'Nepodpísané',
          icon: 'recorded',
          href: `${routes.extranet.prihlasky}?state=recorded`,
        },
        {
          title: 'V riešení',
          icon: 'inSolutions',
          href: `${routes.extranet.prihlasky}?state=inSolutions`,
        },
        {
          title: 'Odmietnuté',
          icon: 'rejected',
          href: `${routes.extranet.prihlasky}?state=rejected`,
        },
        {
          title: 'Späťvzaté',
          icon: 'inSolutions',
          href: `${routes.extranet.prihlasky}?state=takenBack`,
        },
        {
          title: 'Vypršala SMS',
          icon: 'rejected',
          href: `${routes.extranet.prihlasky}?state=expired`,
        },
      ],
    },
    productionDashboardReport: [
      { state: 'N', category: 'Novorodenec' },
      { state: 'Z', category: 'Zmena zdravotnej poisťovne' },
      { state: 'O', category: 'Vznik poistenia' },
    ],
    datepickerButton: 'Vybrať iný dátum',
    claim: 'Staráme sa o vyše 1,6 milióna poistencov',
    fluctuant: {
      title: 'Over prihlášku',
      label: 'Zadajte rodné číslo (bez lomky)',
    },
  },
  applications: {
    title: 'Prihlášky',
    noData: 'Žiadne údaje',
    noApplications:
      'Sem budú priebežne pribúdať vami evidované prihlášky.<br/>Zatiaľ nemáte v evidencii žiadnu.',
    filters: {
      left: {
        recorded: { title: 'Evidované', icon: 'recorded' },
        accepted: { title: 'Akceptované', icon: 'accepted' },
        inSolutions: { title: 'V riešení', icon: 'inSolutions' },
        rejected: { title: 'Zamietnuté', icon: 'rejected' },
        expired: { title: 'Vypršala SMS', icon: 'rejected' },
        takenBack: { title: 'Späťvzatá', icon: 'inSolutions' },
      },
      right: {
        everything: { title: 'Všetko', icon: '' },
        selectAFromDate: { title: 'Vybrať dátum od', icon: 'calendar' },
        selectAToDate: { title: 'Vybrať dátum do', icon: 'calendar' },
      },
    },
    selectLabel: 'Filter stavov',
    selectLabelApp: 'Filter stavov prihlášok',
    selectPlaceholder: 'Vyberte stav',
    tableHeadings: {
      datumZaevidovania: 'Dátum zaevidovania',
      priezviskoAMeno: 'Meno a priezvisko (Rok)',
      telefonneCislo: 'Mobil',
      obec: 'Obec',
      odmenaBrutto: 'Odmena brutto',
      typ: 'Typ',
      stav: 'Stav',
      employeeId: 'Číslo zamestnanca',
    },
    numberOfApplication: (count: number) =>
      `Za vybrané obdobie je zaregistrovaných <strong>${count}</strong> prihlášok.`,
  },
  payslips: {
    title: 'Výplatné pásky',
  },
  subordinates: {
    title: 'Podriadení',
    pageTitle: 'Prehľad produkcie podriadeného',
    tableHeadings: {
      dohodaOd: 'Dohoda od',
      priezviskoAMeno: 'Meno a priezvisko',
      employeeId: 'Číslo zamestnanca',
      newborn: 'Novorodenci',
      changeIC: 'Zmena ZP',
      newInsurance: 'Vznik',
      count: 'Spolu',
    },

    datepicker: {
      from: 'Produkcia od',
      to: 'Produkcia do',
    },
    numberOfSubordinates: (count: number) =>
      `Za vybrané obdobie je zobrazených <strong>${count}</strong> podriadených.`,
  },
  contracts: {
    status: {
      unfinished: 'Rozpracovaná',
      signed: 'Podpísaná',
      inSolutions: 'Vyžaduje Vašu pozornosť',
      accepted: 'Platná',
    },
    tableHeadings: {
      datumZaevidovania: 'Dátum zaevidovania',
      priezviskoAMeno: 'Priezvisko a meno',
      stav: 'Stav',
      txs: 'TXS',
      platnostZmluvy: 'Platnosť zmluvy',
    },
    edit: _common.upravit,
    end: 'Ukončiť dohodu',
    startNewContract: 'Zadať novú dohodu',
    noContracts: {
      title: 'Dohoda o vykonaní práce',
      description: `
        <p>
          Na tejto stránke máte možnosť vypniť všetky údaje potrebné k
          uzatvorneniu novej Dohody o vykonaní práce. Po zadaní všetkých údajov
          viete návrh Dohody priamo digitálne podpísať.
        </p>
      `,
      alert: `
          <b>Pripravte si preukaz totožnosti.</b> Bez priloženia fotografie
          dokladu totožnosti nie je možné úspešne prejsť všetkými krokmi
          formuláru k Dohode o vykonaní práce.
        `,
    },
  },
  contractsManagement: {
    downloadFile: _common.stiahnut,
    deleteFile: _common.vymazat,
    uploadFile: _common.nahrat,
    noApplications:
      'Sem budú priebežne pribúdať dohody.<br/>Zatiaľ nemáte v evidencii žiadnu.',
    filter: {
      dateFrom: {
        label: 'Vybrať dátum od',
      },
      dateTo: {
        label: 'Vybrať dátum do',
      },
      applicationStatus: {
        label: 'Stav dohody',
        all: 'Všetky stavy',
      },
      errorType: {
        label: 'Typ chyby',
        all: 'Všetky typy',
      },
      rewardType: {
        label: 'Typ odmeňovania',
        all: 'Všetky typy',
      },
      employeeId: {
        label: 'Kód zamestnanca',
      },
      draft: {
        label: 'Rozpracovaná',
        yes: 'Áno',
        no: 'Nie',
      },
      signedByOlz: {
        label: 'Podpisaná OĽZ',
        yes: 'Áno',
        no: 'Nie',
        all: 'Všetky',
      },
      lastName: {
        label: 'Priezvisko',
      },
      firstName: {
        label: 'Meno',
      },
      birthNumber: {
        label: 'Rodné číslo',
      },
      expanded: 'Rozšírený filter',
      submit: 'Filtrovať',
      reset: 'Zrušiť filter',
    },
    tableHeadings: {
      date: 'Dátum zaevidovania',
      dateOLZ: 'Dátum odoslania na ĽZ',
      name: 'Priezvisko a meno',
      applicationStatus: 'Stav',
      birthNumber: 'Rodné číslo',
      employeeId: 'Kód zamestnanca',
      rewardType: 'Typ odmeňovania',
      note: 'Poznámka',
      action: 'Akcia',
    },
    detail: {
      title: (name: string) => `Dohoda - ${name}`,
      attachedDocuments: 'Priložené súbory',
      addNote: 'Pridať poznámku',
      actionButtons: {
        sendToTXSLabel: 'Poslať dohodu do TXS',
        signContractLabel: 'Podpísať dohodu zamestnávateľom',
        cancelContractLabel: 'Storno dohody',
      },
      stornoModal: {
        header: 'Správa pre zamestnanca',
        description: 'Tu mozte napisat spravu pre zamestnanca.',
        stornoBtn: 'Stornovať',
        stornoNotificationBtn: 'Stornovať bez notifikácie',
        notification: {
          label: 'Správa',
        },
      },
    },
    ending: {
      documentDownloadDate: 'Dátum stiahnutia dokumentu',
      nameSurname: 'Meno a priezvisko',
    },
  },
  cancelContract: 'Ukončiť dohodu',
  terminationContract: 'Ukončenie dohody',
  modalContent:
    'Ukončenie dohody bude platné až po potvrdení Odborom HR. Po stiahnutí dokumentu Dohoda o skončení Dohody o vykonaní práce je potrebné vytlačiť 2 kópie a podpísané dokumenty zaslať na adresu:<br />DÔVERA zdravotná poisťovňa, a. s.<br />Odbor HR<br />Einsteinova 25<br />851 01 Bratislava',
  cancel: 'Zrušiť',
  download: 'Stiahnuť dokument',
  notification:
    'Nepodarilo sa vygenerovať dokument. Kontaktujte, prosím, svojho manažéra.',
};
